import { ApplicationRef, ChangeDetectionStrategy, Component, DestroyRef, effect, ElementRef, inject, Injector, input, OnInit, signal, viewChild, ViewContainerRef } from "@angular/core";
import { merge, timer } from "rxjs";
import { createTextMatcher, mapNotNull, MMSI } from "common";
import { bufferTime, debounceTime, map, tap } from "rxjs/operators";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { GeoJSONSource, LngLat, Map as MapBoxMap, NavigationControl } from "mapbox-gl";
import { VesselPopupComponent, VesselPopupDelegate } from "../vessel-popup/vessel-popup.component";
import { defaultMapOptions, MapOptions, MapOptionsComponent, MapOptionsDelegate } from "../map-options/map-options.component";
import { VesselData } from "../vessel-data";
import { DistanceMeasurer } from "../distance-measurer/distance-measurer";
import { MapboxAngularPopup } from "../mapbox/mapbox-angular-popup";
import { MapDataProvider } from "../map-data-provider.service";
import { CameraFeature, createCameraFeatures, createPilotBoardingAreaFeatures, createPointOfInterestFeatures, createStationFences, createVesselFeatures, createVesselLeaders, VesselFeature } from "../geojson-mappings";
import { mapBoxZoomEvents } from "../mapbox/mapbox-rx";
import { VesselLocationEvent } from "../types";
import { CameraPopupComponent } from "../camera-popup/camera-popup.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { cameraOptionsFromUrl, isLngLat, VesselMap } from "./map-utils";

const LAYER_VESSELS = "vessels";
const LAYER_DISTANCE_MEASURER = "distance-measurer";
const LAYER_CHART_SYMBOLS = "chart-symbols";
const LAYER_CUSTOM_PILOT_BOARDING_AREAS = "custom-pilot-boarding-areas";
const LAYER_CAMERAS = "cameras";
const SOURCE_OPENSEAMAP = "openseamap-source";
const SOURCE_POINTS_OF_INTEREST = "points-of-interest-source";
const SOURCE_CAMERAS = "cameras-source";
const SOURCE_VESSELS = "vessels-source";
const SOURCE_VESSEL_LEADERS = "vessel-leaders-source";
const SOURCE_DISTANCE_MEASURER = "distance-measurer-source";
const SOURCE_STATION_FENCES = "station-fences-source";
const SOURCE_CUSTOM_PILOT_BOARDING_AREAS = "custom-pilot-boarding-areas-source";

@Component({
    selector: "app-map",
    template: `
        <div class="!absolute top-0 bottom-0 left-0 right-0" #mapElement></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    host: {
        "class": "!block",
        "(window:keydown.Alt)": "altPressed.set(true)",
        "(window:keyup.Alt)": "altPressed.set(false)",
        "(keydown.Escape)": "onEscape()",
    },
})
export class MapComponent implements OnInit, VesselPopupDelegate, MapOptionsDelegate {

    private readonly mapDataProvider = inject(MapDataProvider);
    private readonly applicationRef = inject(ApplicationRef);
    private readonly viewContainerRef = inject(ViewContainerRef);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly router = inject(Router);
    private readonly injector = inject(Injector);
    private readonly destroyRef = inject(DestroyRef);

    readonly mapElement = viewChild.required<ElementRef>("mapElement");

    private readonly vesselMap = new VesselMap();
    private distanceMeasurers: DistanceMeasurer[] = [];
    private readonly options = signal(defaultMapOptions);
    private mapBox!: MapBoxMap;
    private openedVesselPopup: MapboxAngularPopup<VesselPopupComponent> | null = null;
    private openedCameraPopup: MapboxAngularPopup<CameraPopupComponent> | null = null;
    private readonly measuringDistance = signal(false);
    readonly altPressed = signal(false);
    private readonly overVessel = signal(false);
    private readonly overCamera = signal(false);

    internal = input(false);

    ngOnInit(): void {
        this.mapBox = new MapBoxMap({
            container: this.mapElement().nativeElement,
            style: "mapbox://styles/finnpilot-pilotweb/ckf4aexrz0wpo19qo1862l6v4",
            center: new LngLat(24.5, 62.0),
            zoom: 5,
            ...cameraOptionsFromUrl(this.activatedRoute.params),
            minZoom: 4,
            maxZoom: 14,
            dragRotate: false,
            pitchWithRotate: false,
            touchPitch: false,
            maxBounds: [
                {lat: 52, lng: 0},  // sw
                {lat: 72, lng: 50}, // ne
            ],
            accessToken: "pk.eyJ1IjoiZmlubnBpbG90LXBpbG90d2ViIiwiYSI6ImNrZjN6ajc5bTA4NmIydm9mMzNkMnZuM2sifQ.W0owJnRiMP4cTF8En-IHCQ"
        });

        this.mapBox.addControl(new NavigationControl({showCompass: false}), "top-left");

        // Allow touch zooming, but disallow rotation
        this.mapBox.touchZoomRotate.disableRotation();

        this.mapBox.on("load", () => this.initMap());

        effect(() => {
            if (this.altPressed() || this.measuringDistance())
                this.mapBox.getCanvas().style.cursor = "crosshair";
            else if (this.overCamera() || this.overVessel())
                this.mapBox.getCanvas().style.cursor = "pointer";
            else
                this.mapBox.getCanvas().style.cursor = "";
        }, {injector: this.injector});
    }

    initMap(): void {
        // Hide the server-defined pilot-boarding-areas layer
        // TODO: once the this code has been deployed, we can remove the old layer from MapBox
        //       and then remove this
        if (this.mapBox.getLayer("pilot-boarding-areas"))
            this.mapBox.setLayoutProperty("pilot-boarding-areas", 'visibility', 'none');

        this.initOpenSeaMapLayer();
        this.initVesselLayers();
        this.initDistanceMeasurerLayer();
        this.initPilotBoardingAreas();

        if (this.internal()) {
            this.initPointsOfInterest();
            this.initStationFences();
            this.initCameras();
        }

        this.mapBox.on("click", e => {
            if (this.measuringDistance())
                return;

            if (e.originalEvent.altKey) {
                this.startDistanceMeasurement(e.lngLat);
            } else {
                const vessels = this.mapBox.queryRenderedFeatures(e.point, {layers: [LAYER_VESSELS]}) as unknown as VesselFeature[];
                if (vessels.length) {
                    // If there are multiple vessels on top of each other, prefer pilotages
                    const feature = vessels.find(it => it.properties.hasPilotage) ?? vessels[0];
                    const vessel = this.vesselMap.findVessel(feature.properties.mmsi);
                    if (vessel) {
                        const coords = feature.geometry.coordinates;
                        this.openVesselPopup(vessel, new LngLat(coords[0], coords[1]));
                    }
                }
            }
        });

        this.mapBox.on("mouseenter", LAYER_VESSELS, () => this.overVessel.set(true));
        this.mapBox.on("mouseleave", LAYER_VESSELS, () => this.overVessel.set(false));

        this.updateMapData();

        this.mapBox.addControl(
            MapOptionsComponent.create(this.applicationRef, this.viewContainerRef, this.injector, this),
            "top-right"
        );

        const updateMapFromParams = (ps: Params) => void this.mapBox.jumpTo(cameraOptionsFromUrl(ps));

        updateMapFromParams(this.activatedRoute.snapshot);

        this.mapDataProvider.pilotages$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(ps => {
            this.vesselMap.updatePilotages(ps);

            this.updateMapData();
        });

        // Check expirations periodically
        timer(60_000, 60_000).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.vesselMap.removeExpiredVessels();
            this.updateMapData();
        });

        // Map coordinates and zoom level in and out
        this.activatedRoute.queryParams.subscribe(updateMapFromParams);

        mapBoxZoomEvents(this.mapBox).pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            const center = this.mapBox.getCenter();
            this.router.navigate([], {
                queryParams: {
                    // Use accuracy of about 100m for coordinates
                    lat: center.lat.toFixed(3),
                    lng: center.lng.toFixed(3),
                    zoom: this.mapBox.getZoom().toFixed(2)
                },
                replaceUrl: true,
                queryParamsHandling: "merge"
            });
        });

        const processLocationEvents = (batch: ReadonlyArray<VesselLocationEvent>): void => {
            this.vesselMap.processLocationBatch(batch);

            const openPopup = this.openedVesselPopup;
            if (openPopup != null) {
                for (const event of batch) {
                    // If a popup is open for this event, update the location of the popup
                    if (openPopup.componentInstance.mmsi === event.mmsi)
                        openPopup.setLocation(new LngLat(event.point.lng, event.point.lat));
                }
            }
        };

        // TODO: perform full refetches if we detect that the page has been paused

        this.mapDataProvider.getCurrentAisData().then(data => {
            processLocationEvents(mapNotNull(data.vessels, it => it.location));
            this.vesselMap.processMetadataBatch(mapNotNull(data.vessels, it => it.metadata));

            this.updateMapData();

            const mmsi = +this.activatedRoute.snapshot.queryParams["mmsi"];
            if (mmsi)
                this.selectVesselByMmsi(mmsi as MMSI);

            const locations$ = this.mapDataProvider.subscribeToLocationUpdates(data).pipe(tap(processLocationEvents));
            const metadata$ = this.mapDataProvider.subscribeToMetadataUpdates(data).pipe(tap(b => this.vesselMap.processMetadataBatch(b)));

            merge(locations$, metadata$).pipe(map(() => null), bufferTime(10_000), takeUntilDestroyed(this.destroyRef)).subscribe({
                next: () => this.updateMapData(),
                error: e => console.error("Failed to receive location/metadata update", e),
            });
        });
    }

    private initOpenSeaMapLayer(): void {
        this.mapBox.addSource(SOURCE_OPENSEAMAP, {
            type: "raster",
            tiles: ["https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"],
            minzoom: 6,
            maxzoom: 18,
            attribution: "Map data: &copy; <a href='https://www.openseamap.org'>OpenSeaMap</a> contributors"
        });

        this.mapBox.addLayer({id: LAYER_CHART_SYMBOLS, type: "raster", source: SOURCE_OPENSEAMAP});
    }

    private initVesselLayers(): void {
        this.mapBox.addSource(SOURCE_VESSELS, {type: "geojson", data: {type: "FeatureCollection", features: []}});
        this.mapBox.addLayer({
            id: LAYER_VESSELS,
            type: "symbol",
            source: SOURCE_VESSELS,
            layout: {
                "icon-image": "{iconImage}",
                "text-field": "{title}",
                "text-font": [
                    "Open Sans Semibold",
                    "Arial Unicode MS Bold"
                ],
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "icon-ignore-placement": true,
                "text-justify": "left",
                "text-offset": [1, 1.25],
                "text-anchor": "left",
                "icon-rotate": ["get", "heading"],
                "text-size": {
                    "stops": [
                        [0, 0],
                        [8.9, 0],
                        [9, 10]
                    ],
                }
            }
        });

        this.mapBox.addSource(SOURCE_VESSEL_LEADERS, {type: "geojson", data: {type: "FeatureCollection", features: []}});
        this.mapBox.addLayer({
            id: "vessel-leaders",
            type: "line",
            source: SOURCE_VESSEL_LEADERS,
            minzoom: 6,
            paint: {
                "line-color": "#777"
            }
        });
    }

    private initDistanceMeasurerLayer(): void {
        this.mapBox.addSource(SOURCE_DISTANCE_MEASURER, {type: "geojson", data: {type: "FeatureCollection", features: []}});
        this.mapBox.addLayer({
            id: LAYER_DISTANCE_MEASURER,
            type: "line",
            source: SOURCE_DISTANCE_MEASURER,
            layout: {
                "line-cap": "round",
                "line-join": "round",
            },
            paint: {
                "line-dasharray": [2, 2],
                "line-color": "#3388ff",
                "line-width": ["get", "width"]
            }
        });
    }

    private async initPilotBoardingAreas(): Promise<void> {
        const areas = await this.mapDataProvider.findPilotBoardingAreas();

        // Add our custom pilot boarding areas layer
        this.mapBox.addSource(SOURCE_CUSTOM_PILOT_BOARDING_AREAS, {
            type: 'geojson',
            data: createPilotBoardingAreaFeatures(areas),
        });

        this.mapBox.addLayer({
            id: LAYER_CUSTOM_PILOT_BOARDING_AREAS,
            type: 'symbol',
            source: SOURCE_CUSTOM_PILOT_BOARDING_AREAS,
            layout: {
                'text-field': '{name}',
                'text-size': 10,
                'icon-image': 'pba',
                'text-anchor': 'left',
                'text-offset': [1.5, 0]
            },
            paint: {
                'text-color': '#d400d4'
            }
        });
    }

    private async initPointsOfInterest(): Promise<void> {
        const points = await this.mapDataProvider.findPointsOfInterest();

        if (points.length === 0) return;

        this.mapBox.addSource(SOURCE_POINTS_OF_INTEREST, {type: "geojson", data: createPointOfInterestFeatures(points)});

        this.mapBox.addLayer({
            id: "pilotweb-points-of-interest",
            type: "symbol",
            source: SOURCE_POINTS_OF_INTEREST,
            paint: {
                "text-color": "#10520a"
            },
            layout: {
                "text-field": "{name}",
                "text-size": 10,
                "icon-image": "{icon}",
                "text-anchor": "bottom",

                "text-offset": [
                    0,
                    -0.8
                ]
            },
        }, LAYER_VESSELS);
    }

    private async initStationFences(): Promise<void> {
        const fences = await this.mapDataProvider.findStationFences();

        this.mapBox.addSource(SOURCE_STATION_FENCES, {type: "geojson", data: createStationFences(fences)});

        this.mapBox.addLayer({
            id: "station-fences",
            type: "line",
            source: SOURCE_STATION_FENCES,
            minzoom: 12,
            paint: {
                "line-dasharray": [2, 2],
                "line-color": "#000",
                "line-width": 1
            }
        });
    }

    private initCameras(): void {
        this.mapBox.addSource(SOURCE_CAMERAS, {type: "geojson", data: {type: "FeatureCollection", features: []}});

        this.mapBox.addLayer({
            id: LAYER_CAMERAS,
            type: "symbol",
            source: SOURCE_CAMERAS,
            paint: {
                "text-color": "#ff0000"
            },
            layout: {
                "text-field": "{name}",
                "text-size": {
                    "stops": [
                        [0, 0],
                        [10.9, 0],
                        [11, 10]
                    ],
                },
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "text-ignore-placement": true,
                "icon-ignore-placement": true,
                "icon-image": "camera",
                "text-anchor": "bottom",
                "text-offset": [
                    0,
                    -0.8
                ],
            },
        }, "pilot-boarding-areas");


        this.mapBox.on("click", LAYER_CAMERAS, e => {
            e.preventDefault();
            e.originalEvent.stopPropagation();

            if (this.measuringDistance())
                return;

            const features = (e?.features ?? []) as unknown[] as CameraFeature[];
            this.openCameraPopup(features);
        });

        this.mapBox.on("mouseenter", LAYER_CAMERAS, () => this.overCamera.set(true));
        this.mapBox.on("mouseleave", LAYER_CAMERAS, () => this.overCamera.set(false));

        this.mapDataProvider.cameras$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(cameras => {
            const source = this.mapBox.getSource(SOURCE_CAMERAS) as GeoJSONSource;
            source.setData(createCameraFeatures(cameras));
        });
    }

    /**
     * Convert in-memory data to GeoJSON and send it to map component.
     */
    private updateMapData(): void {
        const options = this.options();
        const vesselsSource = this.mapBox.getSource(SOURCE_VESSELS) as GeoJSONSource;
        const vesselLeadersSource = this.mapBox.getSource(SOURCE_VESSEL_LEADERS) as GeoJSONSource;

        const vessels = this.displayableVessels(options.pilotagesOnly);
        vesselsSource.setData(createVesselFeatures(vessels));
        vesselLeadersSource.setData(createVesselLeaders(vessels, options.projectedCourseMinutes));
        this.distanceMeasurers.forEach(it => it.recalculateStart());

        this.mapBox.setLayoutProperty(LAYER_DISTANCE_MEASURER, "visibility", this.distanceMeasurers.length !== 0 ? "visible" : "none");
        this.mapBox.setLayoutProperty(LAYER_CHART_SYMBOLS, "visibility", options.chartSymbols ? "visible" : "none");
    }

    private closeExistingPopups(): void {
        this.openedVesselPopup?.remove();
        this.openedVesselPopup = null;

        this.openedCameraPopup?.remove();
        this.openedCameraPopup = null;
    }

    private openVesselPopup(vessel: VesselData, lngLat: LngLat): void {
        this.closeExistingPopups();

        this.openedVesselPopup = VesselPopupComponent.create(this.mapBox, this.applicationRef, this.viewContainerRef, this.injector, vessel, this);
        this.openedVesselPopup.onClose(() => this.openedVesselPopup = null);
        this.openedVesselPopup.setLocation(lngLat);
    }

    private openCameraPopup(cameras: CameraFeature[]): void {
        if (cameras.length === 0)
            return;

        const lngLat = LngLat.convert(cameras[0].geometry.coordinates as [number, number]);
        this.closeExistingPopups();

        this.openedCameraPopup = CameraPopupComponent.create(this.mapBox, this.applicationRef, this.viewContainerRef, this.injector, cameras);
        this.openedCameraPopup.onClose(() => this.openedCameraPopup = null);
        this.openedCameraPopup.setLocation(lngLat);
    }

    onEscape(): void {
        this.closeExistingPopups();
        this.removeAllDistanceMeasurements();
    }

    startDistanceMeasurement(data: VesselData | LngLat): void {
        this.openedVesselPopup?.remove();
        this.distanceMeasurers.forEach(it => it.stopMeasuring());

        let vessel: VesselData | undefined = undefined;
        let startPoint: () => LngLat;
        if (isLngLat(data)) {
            startPoint = () => data;
        } else {
            vessel = data;
            startPoint = () => data.location;
        }

        this.distanceMeasurers.push(new DistanceMeasurer(
            this,
            this.mapBox,
            vessel,
            startPoint,
            [LAYER_CUSTOM_PILOT_BOARDING_AREAS],
            this.applicationRef,
            this.viewContainerRef,
            this.injector
        ));
        this.updateDistanceLayer();
        this.measuringDistance.set(true);
        this.updateMapData();
    }

    removeAllDistanceMeasurements(): void {
        if (this.distanceMeasurers.length !== 0) {
            for (const measurer of this.distanceMeasurers)
                measurer.dispose();

            this.distanceMeasurers = [];
            this.measuringDistance.set(false);
            this.updateMapData();
        }
    }

    updateDistanceLayer(): void {
        const source = this.mapBox.getSource(SOURCE_DISTANCE_MEASURER) as GeoJSONSource;

        source.setData({
            type: "FeatureCollection",
            features: this.distanceMeasurers.map(it => it.createRenderFeature())
        });
    }

    distanceMeasuringStopped(_: DistanceMeasurer): void {
        this.recalculateMeasuringDistance();
    }

    removeDistanceMeasurer(measurer: DistanceMeasurer): void {
        this.distanceMeasurers = this.distanceMeasurers.filter(it => it !== measurer);
        measurer.dispose();
        this.updateDistanceLayer();
        this.updateMapData();

        this.recalculateMeasuringDistance();
    }

    private recalculateMeasuringDistance(): void {
        this.measuringDistance.set(this.distanceMeasurers.some(it => !it.isStopped()));
    }

    findVessels(query: string, pilotagesOnly: boolean): VesselData[] {
        if (query === "") return [];

        const matcher = createTextMatcher(query);
        return this.displayableVessels(pilotagesOnly).filter(v => v.hasLocation && matcher([v.title, v.mmsi.toString()]));
    }

    private displayableVessels(pilotagesOnly: boolean): VesselData[] {
        const allVessels = this.vesselMap.getVessels();
        const additionalVessels = new Set(mapNotNull(this.distanceMeasurers, it => it.vesselMmsi));
        return pilotagesOnly ? allVessels.filter(it => it.isPilotageRelated || additionalVessels.has(it.mmsi)) : allVessels;
    }

    private selectVesselByMmsi(mmsi: MMSI): void {
        const vessel = this.vesselMap.findVessel(mmsi);
        if (vessel)
            this.vesselSelected(vessel);
    }

    vesselSelected(vessel: VesselData): void {
        const location = new LngLat(vessel.location.lng, vessel.location.lat);
        if (this.mapBox.getCenter().distanceTo(location) <= 0.01) {
            this.openVesselPopup(vessel, vessel.location);
        } else {
            this.mapBox.flyTo({center: location, animate: true});
            this.mapBox.once("moveend", () => this.openVesselPopup(vessel, location));
        }
    }

    optionsChanged(options: MapOptions): void {
        this.options.set(options);

        this.updateMapData();
    }
}
