import { Params } from "@angular/router";
import { CameraOptions, LngLat } from "mapbox-gl";
import { ACTIVE_PILOTAGE_STATES, compareState, Duration, Instant, MMSI } from "common";
import { PilotageInfo, VesselLocationEvent, VesselMetadataEvent } from "../types";
import { VesselData } from "../vessel-data";

const LOCATION_EVENT_VALIDITY = Duration.ofMinutes(15);

export function cameraOptionsFromUrl(params: Params): CameraOptions {
    const zoom = parseFloat(params["zoom"]);
    const lat = parseFloat(params["lat"]);
    const lng = parseFloat(params["lng"]);

    const result: CameraOptions = {};
    if (!Number.isNaN(lat) && !Number.isNaN(lng))
        result.center = new LngLat(lng, lat);
    if (zoom)
        result.zoom = zoom;

    return result;
}

export function isLngLat(data: unknown): data is LngLat {
    return (data as LngLat).lat !== undefined;
}

export class VesselMap {
    private readonly vesselsByMMSI = new Map<MMSI, VesselData>();

    getVessels(): VesselData[] {
        return Array.from(this.vesselsByMMSI.values());
    }

    findVessel(mmsi: MMSI): VesselData | undefined {
        return this.vesselsByMMSI.get(mmsi);
    }

    private findOrCreateVessel(mmsi: MMSI): VesselData {
        let vessel = this.vesselsByMMSI.get(mmsi);
        if (vessel == null) {
            vessel = new VesselData(mmsi);
            this.vesselsByMMSI.set(mmsi, vessel);
        }
        return vessel;
    }

    processLocationBatch(batch: readonly VesselLocationEvent[]): void {
        const validityCutoff = Instant.now().minus(LOCATION_EVENT_VALIDITY);
        for (const event of batch) {
            if (event.timestamp.isBefore(validityCutoff) || (event.sog ?? 0) > 40)
                continue; // skip crap

            this.findOrCreateVessel(event.mmsi).updateLocation(event);
        }
    }

    processMetadataBatch(batch: readonly VesselMetadataEvent[]): void {
        for (const vessel of batch)
            this.findOrCreateVessel(vessel.mmsi).updateMetadata(vessel);
    }

    removeExpiredVessels(): void {
        const cutoff = Instant.now().minus(LOCATION_EVENT_VALIDITY);
        for (const vessel of this.getVessels())
            if (vessel.isExpired(cutoff)) {
                this.vesselsByMMSI.delete(vessel.mmsi);
            }
    }

    updatePilotages(ps: PilotageInfo[]): void {
        const pilotages = pickBestPilotageForEachVessel(ps.filter(it => ACTIVE_PILOTAGE_STATES.includes(it.state)));

        const pilotageMmsis = new Set<number>();
        for (const pilotage of pilotages)
            if (pilotage.vesselMmsi != null) {
                const mmsi = pilotage.vesselMmsi;
                pilotageMmsis.add(mmsi);

                const vessel = this.findOrCreateVessel(mmsi);
                vessel.pilotage = pilotage;
            }

        for (const vessel of this.getVessels())
            if (!pilotageMmsis.has(vessel.mmsi))
                vessel.pilotage = null;

    }
}

function pickBestPilotageForEachVessel(pilotages: ReadonlyArray<PilotageInfo>): PilotageInfo[] {
    const pilotagesByMMSI = new Map<number, PilotageInfo>();

    for (const pilotage of pilotages) {
        if (pilotage.vesselMmsi != null) {
            const mmsi = pilotage.vesselMmsi;
            const previous = pilotagesByMMSI.get(mmsi);
            if (previous == null || compareState(pilotage.state, previous.state) > 0)
                pilotagesByMMSI.set(mmsi, pilotage);
        }
    }

    return Array.from(pilotagesByMMSI.values());
}
