import { Instant, MMSI, PilotageState } from "common";

interface GeoPoint {
    lat: number;
    lng: number;
}

export interface VesselLocationEvent {
    mmsi: MMSI;
    point: GeoPoint
    cog: number | null;
    heading: number | null;
    navStat: number;
    posAcc: boolean;
    rot: number | null;
    sog: number | null;
    timestamp: Instant;
}

export interface VesselMetadataEvent {
    destination: string | null;
    mmsi: MMSI;
    name: string | null;
    shipType: number;
}

export interface PilotageInfo {
    state: PilotageState;
    inVesselNoticeState: boolean;
    vesselMmsi: MMSI | null;
    startCode: string;
    endCode: string;
    startTime: Instant;
    endTime: Instant;
    vesselName: string;
}

export interface PointOfInterestInfo {
    point: {
        lat: number,
        lng: number
    };
    name: string;
    type: string | null;
    icon: string | null;
}

export interface PilotBoardingAreaInfo {
    point: {
        lat: number,
        lng: number
    };
    name: string;
}

export interface CameraInfo {
    point: {
        lat: number,
        lng: number
    };
    id: string;
    name: string;
    lastUpdated: Instant | null;
}

export interface StationFenceInfo {
    points: {
        lat: number;
        lng: number;
    }[];
    name: string;
}

export function parseVesselLocationEvent(json: InstantToString<VesselLocationEvent>): VesselLocationEvent {
    return {
        ...json,
        timestamp: Instant.parse(json.timestamp),
    };
}

export function parseVesselMetadataEvent(json: InstantToString<VesselMetadataEvent>): VesselMetadataEvent {
    // For now all fields are already in correct format so there's nothing to do.
    // If we later add new fields which need custom processing, we need to update this.
    return json;
}

// map a record so that all other types are kept as is, but Instant ot mapped to String
type InstantToString<T> = {
    [K in keyof T]: T[K] extends Instant ? string : T[K]
}
